<template>
  <base-section
    id="k-d-a-newsletter"
    class="black"
    space="12"
  >
    <v-container>
      <v-row
        align="center"
        justify="space-between"
      >
        <v-col
          cols="12"
          md="3"
          lg="4"
        >
          <component
            :is="item.strComponent"
            v-for="(item, i) in m_arrContents"
            :key="i"
            :class="getTextClasses(item.eType, ['justify-start', 'white--text'])"
          >
            {{ item.strText }}
          </component>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <base-text-field
            :tile="false"
            :label="m_strInputLabel"
            background-color="white"
            hide-details
          />
        </v-col>

        <v-col
          cols="12"
          md="3"
          lg="2"
        >
          <base-btn
            color="white"
          >
            {{ m_strButtonLabel }}
          </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import TextClass from '@/mixins/text-class'

  export default {
    name: 'KDANewsletter',

    mixins: [TextClass],

    data: () => ({
      m_strButtonLabel: 'Subscribe',
      m_strInputLabel: 'Your Email Here',
    }),

    computed: {
      m_arrContents () {
        return [
          {
            strText: 'GET OUR NEWSLETTER',
            strComponent: 'h1',
            eType: this.eContentType.TITLE_3,
          },
        ]
      },
    },
  }
</script>
